/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "일곱 번째 에피소드에서는 지난 에피소드에서 다 하지 못 한 AWS re:Invent 2018 이야기와 Japan Container Days v18.12에 대해서 이야기를 했습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://reinvent.awsevents.com/"
  }, "AWS re:Invent 2018 | Amazon Web Services")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2018/11/aws-lambda-supports-ruby/"
  }, "AWS Whats new - AWS Lambda Supports Ruby")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2018/11/aws-lambda-now-supports-custom-runtimes-and-layers/"
  }, "AWS Whats new -  AWS Lambda Now Supports Custom Runtimes, and Enables Sharing Common Code Between Functions")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://shogo82148.github.io/blog/2018/11/30/perl-in-lambda/"
  }, "(일본어) Perl을 AWS Lambda에 실행")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/phusion/traveling-ruby"
  }, "phusion/traveling-ruby: Self-contained, Ruby binaries")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2018/11/alb-can-now-invoke-lambda-functions-to-serve-https-requests/"
  }, "AWS Whats new -  Application Load Balancer can now Invoke Lambda Functions to Serve HTTP(S) Requests")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://qiita.com/advent-calendar/2018/lambda-custom-runtimes"
  }, "(일본어) AWS Lambda Custom Runtimes芸人 Advent Calendar 2018 - Qiita")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/akr/all-ruby"
  }, "akr/all-ruby: Run various versions of ruby command")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/iamseapy/posts/2044313368949043"
  }, "@seapy 딥 레이서 3위")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://containerdays.jp/"
  }, "(일본어) JapanContainerDays v18.12")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubykaigi.org/2018"
  }, "RubyKaigi 2018")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://eventregist.com/e/containerdays1812meetup"
  }, "(일본어) JapanContainerDays v18.12 Meetup｜EventRegist")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/nacyo_t/status/1069802179077140481"
  }, "아키하바라 멘 무사시 부진"), " ", React.createElement(_components.a, {
    href: "https://twitter.com/asbubam"
  }, "@asbubam"), " 님 추천"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/kubernetes/kubernetes"
  }, "kubernetes/kubernetes: Production-Grade Container Scheduling and Management")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cncf.io/"
  }, "Cloud Native Computing Foundation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cncf.io/about/members/"
  }, "Members - Cloud Native Computing Foundation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cncf.io/projects/"
  }, "Projects - Cloud Native Computing Foundation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://envoy.com/"
  }, "Envoy - Visitor Management and iPad Sign in Software | Envoy")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/moby/buildkit"
  }, "moby/buildkit: concurrent, cache-efficient, and Dockerfile-agnostic builder toolkit")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.criu.org/Main_Page"
  }, "CRIU - Checkpoint/Restore In Userspace")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/docker/docker-ce/blob/master/components/cli/experimental/checkpoint-restore.md"
  }, "docker-ce/checkpoint-restore.md at master · docker/docker-ce")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/opencontainers/runtime-spec"
  }, "opencontainers/runtime-spec: OCI Runtime Specification")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/firecracker-microvm/firecracker"
  }, "firecracker-microvm/firecracker")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/firecracker-microvm/firecracker-containerd"
  }, "firecracker-microvm/firecracker-containerd")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=DrrvPqX_Qr8"
  }, "AWS Launchpad - Firecracker is now Open Source")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://katacontainers.io/"
  }, "Kata Containers - The speed of containers, the security of VMs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://nabla-containers.github.io/"
  }, "Nabla containers: a new approach to container isolation · Nabla Containers")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/kubernetes/kubernetes/blob/242a97307b34076d5d8f5bbeb154fa4d97c9ef1d/docs/devel/container-runtime-interface.md"
  }, "CRI: the Container Runtime Interface")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.forbes.com/sites/janakirammsv/2018/01/30/red-hat-acquires-coreos-for-250-million/#5d2537e52ecb"
  }, "Red Hat Acquires CoreOS For $250 Million")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/45"
  }, "하시코프 한국 사용자 모임 첫 번째 밋업 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://byline.network/container_seminar/"
  }, "컨테이너 가상화의 모든 것 - 바이라인 네트워크")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://book.impress.co.jp/"
  }, "(일본어) 임프레스 북스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://jp.wantedly.com/"
  }, "(일본어) Wantedly - 비지니스 SNS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://solacity.jp/cc/"
  }, "(일본어) 오차노미즈 솔라시티 컨퍼런스 센터")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://zozo.jp/zozosuit/"
  }, "(일본어) ZOZOTOWN - ZOZOSUIT")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubykaigi.org/2019"
  }, "RubyKaigi 2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://jinroq.hatenablog.jp/entry/2018/11/13/235205"
  }, "Are you ready to attend RubyKaigi 2019? I'm READY! - 虚無庵")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
